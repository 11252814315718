import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { getHeadings } from '../utils';

const TableOfContent = ({ content }) => {
  const [headings, seHeadings] = useState([]);

  useEffect(() => {
    if (content) seHeadings(getHeadings(content));
  }, [content]);

  if (headings.length <= 0) {
    return <div />;
  }

  return (
    <div className="overflow-hidden border border-neutral-2 rounded-2xl bg-neutral">
      <div className="p-4 ">
        <h1 className="font-bold">Table of Contents</h1>
      </div>
      <div>
        <ul>
          {headings.map((item) => {
            return (
              <AnchorLink
                key={item.hashLink}
                to={`#${item.hashLink}`}
                stripHash
              >
                <li
                  key={item.hashLink}
                  className="py-3 pl-10 pr-4 hover:bg-neutral-1"
                >
                  {item.title}
                </li>
              </AnchorLink>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

TableOfContent.propTypes = {
  content: PropTypes.string.isRequired,
};

export default TableOfContent;
