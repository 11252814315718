import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import BlogSinglePostPage from '../../components/BlogSinglePost';

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      excerpt
      guid
      link
      title
      uri
      content
      slug
      modified(formatString: "MMMM Do, YYYY")
      featuredImage {
        node {
          mediaItemUrl
          altText
          gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      seo {
        focusKeywords
        description
        openGraph {
          type
          slackEnhancedData {
            data
            label
          }
        }
        robots
      }
      postMetadata {
        timeToRead
      }
    }
    allFeaturedWpPost: allWpPost(
      sort: { fields: modified, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { eq: "featured", ne: "archived" } } }
        }
        featuredImageId: { ne: null }
        slug: { ne: $slug }
      }
    ) {
      nodes {
        title
        slug
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            mediaItemUrl
            altText
            gatsbyImage(fit: COVER, width: 600, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

export default function ContentPage(props) {
  return <BlogSinglePostPage data={props.data} location={props.location} />;
}

ContentPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};
