import React from 'react';
import PropTypes from 'prop-types';

import Layout from './layout';
import HeroTitle from './HeroTitle';
import BlogHighlights from './BlogHighlights';
import SubscribeBlock from './SubscribeBlock';
import { CommonContext } from '../context';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlogSharing from './BlogSharing';
import TableOfContent from './TableOfContent';
import BackToTop from './BackToTop';
import { Link } from 'gatsby';

function BlogSinglePost({ data, location }) {
  const {
    title,
    featuredImage,
    content,
    modified,
    seo,
    categories,
    postMetadata,
  } = data.wpPost;
  const featuredBlogs = data.allFeaturedWpPost.nodes;
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname;

  const ReadTime = () => {
    if (!postMetadata?.timeToRead) {
      return null;
    }

    return (
      <>
        <div>|</div>
        <div>{postMetadata.timeToRead} min read</div>
      </>
    );
  };

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        title={title}
        description={seo.description}
        keywords={seo.focusKeywords || []}
        image={featuredImage.node.mediaItemUrl}
        type={seo.openGraph.type}
        additionalData={seo.openGraph.slackEnhancedData}
        location={location}
      >
        <div className="flex justify-center px-6 text-3xl py-7 from-primary-2 bg-gradient-to-r to-primary-3 font-regular md:px-0">
          <div className="w-full max-w-6xl">GTAF BLOG</div>
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-6xl px-6 md:px-0">
            <HeroTitle
              text={title}
              className="m-auto mt-12 mb-8 text-3xl md:text-center md:text-4xl"
            />
            <div className="flex flex-wrap justify-center gap-2 text-sm text-center text-neutral-7">
              {categories.nodes.map((cat, index) => (
                <React.Fragment key={cat.slug}>
                  <Link
                    to={`/blog/category/${cat.slug}`}
                    className="hover:underline"
                  >
                    {cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
                  </Link>
                  {index < categories.nodes.length - 1 && <span>|</span>}
                </React.Fragment>
              ))}
              <div>|</div>
              <div>{modified}</div>
              <ReadTime />
            </div>
            <BlogSharing canonicalUrl={canonicalUrl} />
          </div>
        </div>
        <div className="w-full max-w-[960px] p-4 m-auto mb-6 ">
          <TableOfContent content={content} />
        </div>
        <div className="justify-center hidden max-w-[960px] m-auto md:flex">
          <GatsbyImage
            alt={featuredImage.node.altText}
            image={featuredImage.node.gatsbyImage}
            className="w-full"
            style={{
              width: '1000px',
            }}
          />
        </div>
        <div className="flex justify-center md:hidden">
          <GatsbyImage
            className="w-full"
            alt={featuredImage.node.altText}
            image={featuredImage.node.gatsbyImage}
          />
        </div>
        <div className="flex justify-center mt-0 mb-24 md:mt-8">
          <div className="w-full max-w-[960px] p-6 markdown blog md:p-0">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
        <figure className="hidden" />{' '}
        {/**TODO: Remove this figure after solve purgecss */}
        <BlogHighlights
          title="More From The Blog"
          count={2}
          featuredBlogs={featuredBlogs}
        />
        <SubscribeBlock />
        <BackToTop />
      </Layout>
    </CommonContext.Provider>
  );
}

BlogSinglePost.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogSinglePost;
