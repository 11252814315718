import PropTypes from 'prop-types';
import React from 'react';
import IconComponent from './IconComponent';

const socialLinks = [
  {
    icon: 'facebook-filled',
    url: 'https://www.facebook.com/sharer/sharer.php?u=',
  },
  {
    icon: 'twitter',
    url: 'https://twitter.com/intent/tweet?text=@greentechapps&url=',
  },
  {
    icon: 'linkedin-icon',
    url: 'https://www.linkedin.com/shareArticle?url=',
  },
  {
    icon: 'whatsapp-filled',
    url: 'whatsapp://send?text=',
  },
  {
    icon: 'telegram-filled',
    url: 'https://telegram.me/share/url?url=',
  },
];

const BlogSharing = ({ canonicalUrl }) => (
  <div className="my-4 text-xl text-center md:my-8">
    {socialLinks.map((link) => (
      <a
        key={link.url}
        href={link.url + canonicalUrl}
        target="_blank"
        rel="noreferrer"
        className="mx-3"
      >
        <IconComponent className="w-4 h-4 sm:w-5 sm:h-5" name={link.icon} />
      </a>
    ))}
  </div>
);

BlogSharing.propTypes = {
  canonicalUrl: PropTypes.string.isRequired,
};

export default BlogSharing;
